import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Paginering",
  "template": "default",
  "introtext": "Paginering brukes for å dele opp innhold i mindre deler, slik at brukeren enklere kan navigere seg gjennom det."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Bruk av paginering`}</h2>
    <p>{`Paginering benyttes til søkeresultater og lignende presentasjon av store mengder informasjon som ikke får plass på en enkelt side.`}</p>
    <p>{`For de fleste scenarioer anbefales det å bruke infinite scroll, men paginering kan benyttes hvis det vurderes som bedre basert på brukerfeedback.`}</p>
    <h2>{`Paginering`}</h2>
    <DSExample name="pagination_Pagination" mdxType="DSExample" />
    <h3>{`Paginering med tabelleksempel`}</h3>
    <DSExample name="pagination_Pagination_withTable" mdxType="DSExample" />
    <h2>{`Variasjoner`}</h2>
    <p>{`Det originale eksempelet har tekstene "Neste" og "Forrige". Hvis man ikke ønsker tekst på knappene kan proppen `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`noNavigationText`}</code>{` brukes.`}</p>
    <DSExample name="pagination_Pagination_noNavigationText" mdxType="DSExample" />
    <p>{`Og hvis man ikke ønsker å gi brukeren valg om størrelse på lista, kan man bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`showRowsPerPageText={false}`}</code>{` og la være å ha med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rowsPerPageOptions`}</code>{`.`}</p>
    <DSExample name="pagination_Pagination_buttonsOnly" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      